'use strict'

var page = require('./page');
var util = require('./util');
var dialog = require('./dialog');
var ajax = require('./ajax');
var progress = require('./../../../app_rleu_core/cartridge/js/progress');
var toaster = require('../../../app_foundation/cartridge/js/toaster');

module.exports = function() {
    $('.header-login .header-login-form .input-text').on('blur input', function() {
        if ($('#wrapper').hasClass('login-active')) {
            var loginheight = $('.header-login .header-acc').outerHeight();
            $('.utlity-bkgd-animate').height(loginheight); //for ridiculous animations switching between login and minibag - do not remove
        }
    });
    $('.login-btn').on('click', function(e) {
        e.preventDefault();
        var realForm = $(this).closest('form');
        if (!realForm.valid()){
            return false;
        }
        var iframeId = 'signin-iframe';
        var formId = 'signin-form';
        jQuery('#' + iframeId).remove();
        jQuery('#' + formId).remove();
        var $form = jQuery('<form></form>');
        $form.attr('id', formId);
        $form.attr('action', realForm.attr('action'));
        $form.attr('target', iframeId);
        $form.attr('method', 'POST');

        jQuery('<iframe name="' + iframeId + '" id="' + iframeId + '" />').appendTo('body');
        jQuery('#' + iframeId).attr('style', 'display: none;');

        realForm.find('input, select, textarea, button').each(function(){
            var $input = $(this).clone();
            $form.append($input);
        });

        if (jQuery('#' + iframeId).contents().find('body').length === 1){
            jQuery('#' + iframeId).contents().find('body').append($form);
            var form = jQuery('#' + iframeId).contents().find('form');
            $('.header-login-kakao-error').addClass('hide');
            $.ajax({
                type: 'POST',
                url: form.attr('action'),
                data: form.serialize(),
                async : false,
                crossDomain  : true,
                xhrFields: {
                    withCredentials: true
                },
                success: function(res){
                    var resStr = JSON.stringify(res);
                    var loginError = $('.header-login-error');
                    try {
                        //Parse the Message we got in response
                        var data = JSON.parse(resStr);
                        if (data.success){
                            if (data.isEmployee) {
                                headerLoginEmployee();
                                return;
                            } else {
                                var redirectURL = getRedirectURL();
                            }
                            if (pageContext && pageContext.type === 'Returns') {
                                page.refresh();
                            } else if ($('body').find('.pt_product-details').length <= 0) {
                                page.redirect(redirectURL);
                            } else {
                                location.replace(redirectURL);
                            }
                        } else {
                            //If we have error
                            var errorMsg;
                            if (data.errorStatus == '0') {
                                 errorMsg = Resources.LOGIN_ERROR_LOCKED;
                            } else if (data.errorStatus == '1') {
                                errorMsg = Resources.LOGIN_ERROR_WILLBELOCKED;
                            }  else if (data.errorStatus == '7') {
                                 var url = util.appendParamToURL(Urls.loginform,'passwordValFail', true);
                                 page.redirect(url);
                            } else if (data.errorStatus == '10') {
                                var errorMsg = $(document.createElement('div'));
                                errorMsg.attr("class","error-form");

                                var errorLink = $(document.createElement('a'));
                                errorLink.css('color','#ffffff');
                                errorLink.attr("href",data.url);
                                errorLink.html(Resources.LOGIN_SLEEPING_ACCOUNT);

                                errorMsg.html(errorLink);
                            } else {
                                errorMsg = Resources.LOGIN_ERROR;
                            }
                            if (data.errorStatus != '11') {
                                loginError.html(errorMsg);
                            } else {
                                $('.header-login-kakao-error').removeClass('hide');
                            }
                            var loginheight = $('.header-login .header-acc').outerHeight();
                            $('.utlity-bkgd-animate').height(loginheight); //for animations switching between login and minibag - do not remove
                        }
                    } catch (e) {
                        //Do Nothing
                    }
                }
            });
        }
    });
    
    $('.gatedsale-template').on('click', 'button[name="dwfrm_login_login"]', function(e){
        e.preventDefault();
        var $curForm = $(this).closest("form");
        $curForm.validate();
        if (!$curForm.valid()) {
            return false;
        }
        var url = $curForm.attr('action');
        progress.show($(this));
        ajax.load({
            url: url,
            type: 'POST',
            data: $curForm.serialize(),
            callback: function(data) {
                var $employeeAgreementModal = $(data).find('div.employee-shopping-terms');
                var currentLocation = window.location.href;
    	    	var refresh = util.appendParamToURL(currentLocation,'gatesalemsg', 'login');
    	    	window.history.pushState({ path: refresh }, '', refresh);
                if ($employeeAgreementModal.length > 0) {
                	headerLoginEmployee();
                } else {
            		if ($(data).find('.error-form').length > 0 || $(data).find('.error-message').length > 0) {
            			var loginForm = $(data).find('.login-account');
            			$curForm.closest('.login-account').html(loginForm);
            	    } else {
            	    	window.location.href = refresh;
            	    }
                }
            }
        });
    });
    
    $('.gatedsale-template').on('click', 'button[name="dwfrm_profile_confirm"]',  function(e) {
        e.preventDefault();
        var $curForm = $(this).closest("form");
        $curForm.validate();
        if (!$curForm.valid()) {
            return false;
        }
        progress.show($(this));
        var formData = $curForm.serializeArray();
        formData.push({name: this.name, value: this.value});
        var url = $curForm.attr('action');

        $.ajax({
            url: url,
            type: 'POST',
            data: formData,
            success: function(data) {
        		if ($(data).find('.error-form').length > 0 || $(data).find('.error-message').length > 0) {
        			var datahtml = $(data).find('#RegistrationForm');
        			$curForm.closest('#RegistrationForm').html(datahtml.html());
        			$('div').remove('.col-header');
        	    } else {
        	        var email = $('#dwfrm_profile_customer_email').val();
        	        var emailSource = $('#emailPromotionSource').val();
        	        var addtoemaillist = $('#dwfrm_profile_customer_addtoemaillist').is(':checked');
        	    	var currentLocation = window.location.href;
        	    	var refresh = util.appendParamToURL(currentLocation,'gatesalemsg', 'createlogin');
        	        if(addtoemaillist) {
		                $.ajax({
		                    type: 'POST',
		                    url: util.appendParamsToUrl(Urls.emailSignupAjax, {
		                        emailAddress: email,
		                        emailSource: emailSource
		                    }),
		                    success: function(data) {
		            	    	window.location.href = refresh;
		                    }
		                });
        	        } else {
        	        	window.location.href = refresh;
        	        }
        	    }
            }
        });
    });
    
    if ($('#gatesale').length > 0) {
    	var $gatesale = $('#gatesale');
	    setTimeout(function() {
		    $gatesale.addClass('fadein').attr('aria-hidden', 'false');    
	    }, 2000); 
	    setTimeout(function() {
	        $gatesale.attr('aria-hidden', 'true').removeClass('fadein');
	    }, 7000); 
	    $gatesale.find('.close').on('click', 'a', function(e){
	    	e.preventDefault();
	    	$gatesale.attr('aria-hidden', 'true').removeClass('fadein');
	    });
	    var refresh = util.removeParamFromURL(window.location.href, 'gatesalemsg');
	    if (refresh.endsWith('?')) {
	    	refresh = refresh.replace('?', '');
	    }
	    window.history.pushState({ path: refresh }, '', refresh);
    }

}

function getRedirectURL() {
    var url;
    if (window.location.pathname == '/') {
        url = util.appendParamToURL(Urls.homeShow,'LoginLocation','header');
    } else if (window.location.pathname.indexOf('Cart-SubmitForm') > -1) {
        url = util.appendParamToURL(Urls.shipping,'LoginLocation','header');
    } else if (window.location.pathname.indexOf('orderconfirm') > -1 || window.location.pathname.indexOf('Customer-OrderConfirm') > -1 || window.location.pathname.indexOf('Account-SetNewPasswordForm') > -1) {
        url = util.appendParamToURL(Urls.myAccount,'LoginLocation','header');
    } else {
        url = util.appendParamToURL(window.location.href,'LoginLocation','header');
    }
    return url;
}

//a flag to make sure we are not firing the ajax call many times when user clicks the sign in button more than once continuously
var employeeLoginPrgoress = false;

function headerLoginEmployee() {
    var url = util.appendParamToURL(Urls.employeeShoppingTerms,'LoginLocation','header');
    if(!employeeLoginPrgoress) {
        employeeLoginPrgoress = true;
        $.ajax({
            url: url,
            success: function(response) {
                var employeeAgreementModal = $(response).find('div.employee-shopping-terms');
                if (employeeAgreementModal.length > 0) {
                    var content = employeeAgreementModal;
                    if ($(window).width() <= 767) {
                        toaster.teleport($(content), 'bottom', null, 'full-height user-shopping-terms-toaster', true)
                            .then(function ($toaster) {
                                $toaster.find('button.accept-button, button.decline-button').off("click").on("click", function(e) {
                                    e.preventDefault();
                                    var $curForm = $(this).closest('form');
                                    var url = $curForm.attr('action');
                                    var $hiddenInput = jQuery('<input/>', {
                                        type : 'hidden',
                                        name : e.target.name,
                                        value : e.target.value,
                                        'aria-invalid' : 'false'
                                    });
                                    $curForm.append($hiddenInput);
                                    ajax.load({
                                        url: url,
                                        type: 'POST',
                                        data: $curForm.serialize(),
                                        callback: function() {
                                            var redirectURL = getRedirectURL();
                                            if (pageContext && pageContext.type === 'Returns') {
                                                page.refresh();
                                            } else if ($('body').find('.pt_product-details').length <= 0) {
                                                page.redirect(redirectURL);
                                            } else {
                                                location.replace(redirectURL);
                                                location.reload();
                                            }
                                        }
                                    });
                                });
                            });
                    } else {
                        toaster.teleport($(content), 'right', null, 'user-shopping-terms-toaster', true)
                            .then(function ($toaster) {
                                $toaster.find('button.accept-button, button.decline-button').off("click").on("click", function(e) {
                                    e.preventDefault();
                                    var $curForm = $(this).closest('form');
                                    var url = $curForm.attr('action');
                                    var $hiddenInput = jQuery('<input/>', {
                                        type : 'hidden',
                                        name : e.target.name,
                                        value : e.target.value,
                                        'aria-invalid' : 'false'
                                    });
                                    $curForm.append($hiddenInput);
                                    ajax.load({
                                        url: url,
                                        type: 'POST',
                                        data: $curForm.serialize(),
                                        callback: function() {
                                            var redirectURL = getRedirectURL();
                                            if (pageContext && pageContext.type === 'Returns') {
                                                page.refresh();
                                            } else if ($('body').find('.pt_product-details').length <= 0) {
                                                page.redirect(redirectURL);
                                            } else {
                                                location.replace(redirectURL);
                                                location.reload();
                                            }
                                        }
                                    });
                                });
                            });
                    }
                } else {
                    url = util.appendParamToURL(Urls.myAccount,'LoginLocation','header');
                    page.redirect(url);
                }
                employeeLoginPrgoress = false;
                $('.user-shopping-terms-toaster button').blur();
            }
        });
    }
}