'use strict'; 

var page = require('../page'),
    util = require('../util'),
    dialog = require('../dialog'),
    toaster = require('@foundation/js/toaster'),
    accountMenu = require('./../../../../app_rleu_core/cartridge/js/pages/account-menu'),
    validator = require('../validator'),
    ajax = require('../ajax'),
    tabs = require('../../../../app_rleu_core/cartridge/js/tabs'),
    producttile =  require('../product-tile'),
    pagination =  require('../wishlistpagination'),
    progress = require('../../../../app_rleu_core/cartridge/js/progress'),
    initBannerScroll = require('../../../../app_rleu_core/cartridge/js/pages/banner-scroll'),
    minicart = require('../minicart'),
    aep = require('@foundation/js/aepdatacollection-util'),
    Promise = require('promise'),
    myrlQuickFilterDropDown = require('./../../../../app_rleu_core/cartridge/js/pages/myrlQuickFilterDropDown'),
    level1 = require('@foundation/js/components/navigation/level1'),
    enhancedSignin = SitePreferences.ENNANCED_SIGNIN ? require('./enhancedSignin/accountlogin') : null;

//Would it be worth putting wishlist exports/handlers in their own file?
aep.coreCall();

function showConfirmationMsg($element, data) {
	if ($(data).find('.wl-list-confirm').length) {
		// WL List Confirm Msg (createListEnabled)
		var $confirmationMsg = $(data).find('.wl-list-confirm').html();
		$element.addClass('current-product');
		var modalPosition;
		if ($(window).width() < 959) {
        	modalPosition = {
    			at: 'bottom',
    		    my: 'center'
    		}
		} else {
        	modalPosition = {
    			at: 'center',
    		    my: 'top',
    			of: $element.find('.remove-from-favorites'),
    			collision: 'fit flip',
    			using: function( position, feedback ) {
					$(this).css(position);
					$(this).find('.arrow-center').addClass(feedback.vertical);
					if (feedback.vertical == 'top') {
						$('.wl-confirm-modal').css('top', $(this).position().top+20)
					} else if(feedback.vertical == 'bottom') {
						$('.wl-confirm-modal').css('top', $(this).position().top+30)
					}
				}
        	}
		}
		var wlConfirmDialogClass;
		if ($element.closest('.js-redesign-qs-toaster').length) {
            wlConfirmDialogClass = 'qs-wl-confirm-modal wl-confirm-modal wishlist-dialog modal-fadeout-desktop';
        } else {
            wlConfirmDialogClass = 'wl-confirm-modal wishlist-dialog modal-fadeout-desktop';
        }
		dialog.open({
			html: $confirmationMsg,
			options: {
		        dialogClass: wlConfirmDialogClass,
		        width: 'inherit',
		        show: {
		            effect: 'fade',
		            duration: 300
		        },
		        open: function() {
                    // close dialog on orientation change
                    if (screen && screen.orientation) {
                        screen.orientation.addEventListener('change', closeModal, {
                            once: true
                        });
                    } else {
                        window.addEventListener('orientationchange', closeModal, {
                            once: true
                        });
                    }

		        	$('.ui-dialog-title').hide();
		        	$('.ui-widget-header').css('height', '0').css('padding', '0');
	                
	                if ($(window).width() < 959) {
                		$(this).closest('.ui-dialog').addClass('translateYZero');
	                	$('body').css('overflow', 'hidden');
	                	$('.wishlist-dialog')
		                    .css('overflow-y', 'auto')
		                    .css('max-height', '100%');	                	
            		} else {
            			$('.ui-widget-overlay').css('background-color', 'transparent');
                        if ($element.closest('.js-redesign-qs-toaster').length) {
                            $('.ui-widget-overlay').css('zIndex', '103');
                        } else {
                            $('.ui-widget-overlay').css('zIndex', '99');
                        }
            			$element.closest('.plp-favorites').addClass('display');
            			
            			// Arrow Position
            			$('.wl-confirm-modal').find('.arrow-center').position({
            				at: 'center',
            			    my: 'top+10',
            				of: $element.find('.remove-from-favorites')
            			});
        				$('.arrow-center.bottom').css('top', '247px')
            			
            			// Close on background
            			$('.ui-widget-overlay').on('click', closeModal);
        			}

	                wlListActions();
		        },
		        close: function() {
                    if (screen && screen.orientation) {
                        screen.orientation.removeEventListener('change', closeModal);
                    } else {
                        window.removeEventListener('change', closeModal);
                    }

		        	$('body').css('overflow', 'auto');
		        	$element.removeClass('current-product');
		        	$element.closest('.plp-favorites').removeClass('keep');
		        	if (!$element.closest('.product-tile:hover')) {
                        $element.closest('.plp-favorites').removeClass('display');
                    }
		        },
		        focus: function( event, ui ) {
		        	$('.show-create-list').blur();
		        },
	        	position: modalPosition
			}
	    });

	} else if ($(data).find('.confirmation-msg').length) {
		// Default WL Confirm Msg
		
		var $elementMsge;
		if ($element != null) {
			$elementMsge = $element.find('.confirmation-msg');
		} else {
	        $elementMsge = $('.confirmation-msg');
	        if ($('.plp-favorites.fading-in').length) {
	            $('.confirmation-msg').closest('.plp-favorites.fading-in').addClass('display keep');
	        }
		}
		$elementMsge.show();
	    $('.favclear').off('click').on('click', function(e) {
	        $(this).closest('.confirmation-msg').hide();
	    });
	}
}

function selectedWishlistIcon () {
	var selectedWLtileID = $('body').attr('wl-selected-product');
	return $('.add-to-favorites[productId="'+selectedWLtileID+'"]');
}
function siginOverLay(element) {
    $('button[name$="_login_login"]').off("click").on("click", function(e) {
        e.preventDefault();
        if (element == undefined) {
        	element = selectedWishlistIcon();
        } 
        var $curForm = $(this).closest("form");
        $curForm.validate();
        if (!$curForm.valid()) {
            return false;
        }
        var url = $curForm.attr('action');
        progress.show($('.wishlist-favorites'));
        ajax.load({
            url: url,
            type: 'POST',
            data: $curForm.serialize(),
            callback: function(data) {
            	progress.hide();
                //dpid-addtowardrobe :If the response data is JSON and the page type is 'dpidwardrobe', execute the AddToWardrobe-specific code
                var json = util.isJson(data);
                if (json && json.page === 'dpidwardrobe') {
                    util.update_wardrobe_for_old_login_modal(json,page,'signin');
                    return;
                }
                var $userAgreementModal = $(data).find('div.user-shopping-terms');
                if ($userAgreementModal.length > 0) {
                	handleUserAgreementModal($userAgreementModal,element);
                } else {
                    handelWishListConfirmationMsg(data,element);
                }
            }
        });
    });
    

    $(document).on('click', '.apply-button.createAccount', function (e) { 
        e.preventDefault();
        var $curForm = $(this).closest("form");
        $curForm.validate();
        if (!$curForm.valid()) {
            return false;
        }
        var url = $curForm.attr('action');
        url = url.split('=')[0];
        url = util.appendParamToURL($curForm.attr('action'), $(this).attr('name'), true);
        url = util.appendParamToURL(url, 'format', 'ajax');
        progress.show($('.wishlist-favorites'));
        ajax.load({
            url: url,
            type: 'POST',
            data: $curForm.serialize(),
            callback: function (data) {
                progress.hide();
                //dpid-addtowardrobe :If the response data is JSON and the page type is 'dpidwardrobe', execute the AddToWardrobe-specific code
                var json = util.isJson(data);
                if (json && 'isDpidPage' in json && json.isDpidPage || json.page === 'dpidwardrobe') {
                    util.update_wardrobe_for_old_login_modal(json, page, 'createaccount');
                    return;
                } else {
                    //Redirecting to account page if any error in data otherwise page is refreshing for successful sign-up
                    (data && data.error) ? window.location.href = Urls.myAccount : window.location.reload();
                }
            }
        });
    });

    $('.oAuthIcon').on('click', function() {
        var form = $(this).closest('form');
        form.find('#OAuthProvider').val(this.id);
    });
    $('input[name$="login_rememberme"]').on('change', function () {
        var form = $(this).closest('form');
        var formParent = $(form).parent().parent();

        if ($(this).is(':checked')) {
            formParent.find('#oauthrememberme').val('true');
            $('#rememberme').val('true');
        } else {
            formParent.find('#oauthrememberme').val('false');
            $('#rememberme').val('false');
        }
    });
    
    $('input[name$="profile_customer_addtoemaillist"]').on('change', function () {
        var form = $(this).closest('form');
        var formParent = $(form).parent().parent();

        if ($(this).is(':checked')) {
            formParent.find('#oauthaddtoemaillist').val('true');
        } else {
            formParent.find('#oauthaddtoemaillist').val('false');
        }
    });   
    tabs.init();
}


function handleUserAgreementModal ($userAgreementModal,element) {
    $('.save-favorites-dialog button.ui-dialog-titlebar-close').trigger('click');
    var content = $userAgreementModal;
    var toasterClass = 'user-shopping-terms-toaster';
    var from = 'right';
    if ($(window).width() <= 767) {
        toasterClass = 'full-height user-shopping-terms-toaster';
        from = 'bottom';
    }
    toaster.teleport($(content), from, null, toasterClass, true)
        .then(function ($toaster) {
            $toaster.find('button.accept-button, button.decline-button').off("click").on("click", function(e) {
                e.preventDefault();
                var $curForm = $(this).closest("form");
                var url = $curForm.attr('action');
                var $hiddenInput = jQuery('<input/>', {
                    type : 'hidden',
                    name : e.target.name,
                    value : e.target.value,
                    'aria-invalid' : 'false'
                });
                $curForm.append($hiddenInput);
                ajax.load({
                    url: url,
                    type: 'POST',
                    data: $curForm.serialize(),
                    callback: function(data) {
                        handelWishListConfirmationMsg(data,element);
                        // update promotion message
                        var $pid = $('input[name="pid"]').val();
                        if ($pid) {
                            ajax.load({
                                url: util.appendParamToURL(Urls.GetPromotion, 'pid', $pid),
                                type: 'GET',
                                callback: function(data) {
                                    $('.product-variations.pdp-content').find('.promotion').remove();
                                    $('.product-variations.pdp-content').prepend($(data));
                                }
                            });
                        }
                        toaster.close();
                    }
                });
            });
        });
   
}

function handelWishListConfirmationMsg (data,element) {
	if ($(data).find('.error-form').length > 0 || $(data).find('.error-message').length > 0) {
        $('div.save-favorites-dialog').find('div.wishlist-favorites').html(data);
        validator.init();
        validator.initForm();
        validator.passwordConstraints();
        resetPassword();
        siginOverLay(element);
    } else {
    	/*
    	 * cart product removing - page getting reload if user sign - data = '' - logged in success
    	 * wishlist page should reload to reflect signin account page design
    	 */
    	if((element == 'cartPageWishlist' && data == '') || pageContext.ns == 'wishlist') {
        	location.reload();
        } else {
	    	if(element != 'cartPageWishlist') {
	    		element.html(data);
	    	}
	        $('button.ui-dialog-titlebar-close').trigger('click');
	        $.ajax({
	            url: Urls.HTMLHeader,
	            success: function(data) {
	                $('.header-acc').html(data);
	            }
	        });
            $.ajax({
	            url: Urls.MobileAccountHeader,
	            success: function(data) {
	                $('.nav-item-account').replaceWith(data);
                    $('body').trigger('init:navevents');
                    level1.init();
	            }
	        });
	        showConfirmationMsg(element);
	        removeFavorites();
        }
    }
}

function removeFavorites() {
	$(document).on('click','div.add-to-favorites a.remove-from-favorites', function(e) {
        e.preventDefault();
        var $element = $(this).closest('.add-to-favorites');
        var url = util.appendParamToURL(e.target.href, 'action', 'favrate');
        progress.show();
        ajax.load({
            url: url,
            async: false,
            type: 'GET',
            callback: function(data) {
            	if (localStorage.getItem('wishlistjson')) {
                    var pid_removed = $(e.target).closest('.wishlist-pdp').attr('data-item');
                    var wishlistJSON = JSON.parse(localStorage.getItem('wishlistjson'));
                    var $productTile = $(`.product-tile[data-itemid='${pid_removed}']`);
                    wishlistJSON.products = wishlistJSON.products.filter(function(item) {
                        if (item == pid_removed) {
                            return false;
                        }
                        return true;
                    });
                    localStorage.setItem('wishlistjson',JSON.stringify(wishlistJSON));
                    $(e.target).closest('.wishlist-pdp').find('.remove-favorites').hide();
                    $(e.target).closest('.wishlist-pdp').find('.add-to-favorites-link').show();
                    $(e.target).closest('.wishlist-pdp').find('.confirmation-msg').hide();
                    if ($(e.target).closest('.js-redesign-qs-toaster').length) {
                        $productTile.find('.remove-favorites').hide();
                        $productTile.find('.add-to-favorites-link').show();
                    }
                }
                progress.hide();
                var qs = util.getQueryStringParams(url);
                var favPage = qs['favPage'];
         	 	var forYou = qs['forYouHP'];
                if (favPage == 'true' && forYou != 'true') {
                    window.location.reload();
                }
            }
        });
    })
}
function resetPassword() {
    $('.login-passwordreset a').off('click').on('click', function(e) {
        e.preventDefault();
        var url = util.appendParamToURL(e.target.href, 'action', 'favrate');
        ajax.load({
            url: url,
            async: false,
            type: 'GET',
            callback: function(data) {
                $('div.save-favorites-dialog').find('div.wishlist-favorites').html(data);

                validator.init();
                validator.initForm();
                validator.passwordConstraints();
                var $requestPasswordForm = $('[name$="_requestpassword"]');
                var $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');
                $($submit).on('click', function(e) {
                    if (!$requestPasswordForm.valid()) {
                        return;
                    }
                    e.preventDefault();
                    //Iframe
                    var realForm = $(this).closest('form');
                    var iframeId = 'reset-password-iframe';
                    var formId = 'reset-password-form';
                    jQuery('#' + iframeId).remove();
                    jQuery('#' + formId).remove();
                    var $form = jQuery('<form></form>');
                    $form.attr('id', formId);
                    $form.attr('action', realForm.attr('action'));
                    $form.attr('target', iframeId);
                    $form.attr('method', 'POST');

                    jQuery('<iframe name="' + iframeId + '" id="' + iframeId + '" />').appendTo('body');
                    jQuery('#' + iframeId).attr('style', 'display: none;');

                    realForm.find('input, select, textarea, button').each(function() {
                        var $input = $(this).clone();
                        $form.append($input);
                    });
                    jQuery('#' + iframeId).contents().find('body').append($form);
                    var form = jQuery('#' + iframeId).contents().find('form')
                    //Iframe

                    var data = form.serialize();
                    // add form action to data
                    data += '&' + $submit.attr('name') + '=';
                    // make sure the server knows this is an ajax request
                    if (data.indexOf('ajax') === -1) {
                        data += '&format=ajax';
                    }
                    $.ajax({
                        type: 'POST',
                        url: form.attr('action'),
                        data: data,
                        async: false,
                        crossDomain: true,
                        xhrFields: {
                            withCredentials: true
                        },
                        success: function(response) {
                            if (typeof response === 'object' &&
                                !response.success &&
                                response.error === 'CSRF Token Mismatch') {
                                page.redirect(Urls.csrffailed);
                            } else if (typeof response === 'string') {
                                dialog.$container.html(response);
                            }
                        },
                        failure: function() {
                            dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
                        }
                    });
                });
            }
        });
    });
}

function openLoginModal(data, $element) {
    dialog.open({
        html: data,
        options: {
            width: 770,
            dialogClass: 'save-favorites-dialog',
            beforeClose: function () {
                $("body").css("overflow","auto");
                $("div.save-favorites-dialog").removeClass("fullHeight");
            },
            open: function() {
                $("body").css("overflow","hidden");
                $('div.save-favorites-dialog')
                    .css('overflow-y', 'auto')
                    .css('max-height', '100%');
                function modalPopup() {
                    if($(window).width() < 959) {
                        if(navigator.platform == "iPhone") { 
                        	$("div.save-favorites-dialog").removeClass("fullHeight");
                        } else { 
                        	$("div.save-favorites-dialog").addClass("fullHeight");
                        }
                        $('.save-favorites-dialog .input-text').on('focusout', function () {
                            $('html').scrollTop(0);
                        });
                    }else {
                        $("div.save-favorites-dialog").removeClass("fullHeight");
                    }
                }
                modalPopup();
                $(window).on('resize',function() {
                    modalPopup();
                });
                validator.init();
                validator.initForm();
                validator.passwordConstraints();
                resetPassword()
                siginOverLay($element);
                tabs.init();
            }
        }
    });
}

function addFavorites() {
	$(document).off('click', 'div.add-to-favorites a.save-To-favorites').on('click', 'div.add-to-favorites a.save-To-favorites', function (e) {
        e.preventDefault();
        var uurl = window.location.href;
        var pid = $('#productSKU').val();
        if ($('.isGiftCardProduct').val()) {
            var GCAmount = '';
            if ($('.gc-amounts .selected').length !== 0) {
                GCAmount = $('.gc-amounts .selected').data('giftamount');
            }
        }
        var qs = util.getQueryStringParams(uurl);
        var coloridKey='dwvar' + pid +'_colorname';
        var colorID=qs[coloridKey];
        if(colorID != undefined){
        	var decodedColorID=colorID.replace(/%20/g, ' ');
        	var url = util.appendParamsToUrl(e.target.href, {'action': 'favrate', colorId : decodedColorID} );
        } else {
        	var url = util.appendParamsToUrl(e.target.href, {'action': 'favrate'} );
        }
        if ($('.gc-amounts .selected').length !== 0 && GCAmount != '' && typeof GCAmount !== 'undefined') {
            var url = util.appendParamsToUrl(url, {'GCAmount': GCAmount});
        }
        var $element = $(this).closest('.add-to-favorites');
        $('body').attr('wl-selected-product', $element.attr('productid'));	//To show confirmation message for particular product icon on login/create account
        if ($element.closest('.js-redesign-qs-toaster').length) {
            progress.show($('.js-redesign-qs-toaster'));
        } else {
            progress.show();
        }
        ajax.load({
            url: url,
            type: 'GET',
            async: false,
            callback: function(data) {
                if ($(data).find('.login-box-content').length > 0) {
                    openLoginModal(data, $element);
                } else {
                	if (localStorage.getItem('wishlistjson')) 
                    {
                        var pid_add = $(e.target).closest('.wishlist-pdp').attr('data-item');
                        var wishlistjson = JSON.parse(localStorage.getItem('wishlistjson'));
                        wishlistjson.products.push(pid_add);
                        localStorage.setItem('wishlistjson',JSON.stringify(wishlistjson));
     
                     }
                    $(e.target).closest('.wishlist-pdp').find('.remove-favorites').show();
                    $(e.target).closest('.wishlist-pdp').find('.add-to-favorites-link').hide();
                    var $productTile = $(`.product-tile[data-itemid='${pid_add}']`);
                    if ($(e.target).closest('.js-redesign-qs-toaster').length) {
                        $productTile.find('.remove-favorites').show();
                        $productTile.find('.add-to-favorites-link').hide();
                    }

                    showConfirmationMsg($element, data);
                    initTileLogin();
                }
                progress.hide();
            }
        });
    });
}
function cartAddFavorites() {
    $(document).on('click','#cart-table a.move-to-wishlist-from-cart', function(e) {
        e.preventDefault();
        var url = e.target.href;
        ajax.load({
            url: url,
            type: 'GET',
            async: false,
            callback: function(data) {
                if ($(data).find('.login-box-content').length > 0) {
                    dialog.open({
                        html: data,
                        options: {
                            width: 770,
                            dialogClass: 'save-favorites-dialog',
                            beforeClose: function () {
                            	$("div.save-favorites-dialog").removeClass("fullHeight");
                            },
                            open: function() {
                            	function modalPopup() {
                            		if($(window).width() < 959) {
	                        			if(navigator.platform == "iPhone") { $("div.save-favorites-dialog").removeClass("fullHeight");
	                        			} else { $("div.save-favorites-dialog").addClass("fullHeight");}
	                        		}else {
	                					$("div.save-favorites-dialog").removeClass("fullHeight");
	                    			}
                            	}
                            	modalPopup();
                            	$(window).on('resize',function() {
                            		modalPopup();
                            	});
                                validator.init();
                                validator.initForm();
                                validator.passwordConstraints();
                                resetPassword();
                                siginOverLay('cartPageWishlist');
                                tabs.init();
                            }
                        }
                    });
                } else {
                	location.reload();
                }
            }
        });
    });
}

function initFavourites() {
	var wishlistJsonURL = Urls.wishlistJson;
    $.getJSON(wishlistJsonURL, function(data) {
        localStorage.setItem('wishlistjson',JSON.stringify(data));
        $.each( data.products, function( i, item ) {
            $('.wishlist-pdp').filter("[data-item='" + item+ "']").find('.remove-favorites').show();
            $('.wishlist-pdp').filter("[data-item='" + item+ "']").find('.add-to-favorites-link').hide();
        });
        $('div.plp-favorites').addClass('enabled'); //enable clicks after favs are set
    });
}

function setFavourites() {
    var wishListData = localStorage.getItem('wishlistjson') ? JSON.parse(localStorage.getItem('wishlistjson')) : null;
    if (wishListData !== null && wishListData.products && wishListData.products.length > 0) {
        $.each( wishListData.products, function( i, item ) {
            $('.wishlist-pdp').filter("[data-item='" + item+ "']").find('.remove-favorites').show();
            $('.wishlist-pdp').filter("[data-item='" + item+ "']").find('.add-to-favorites-link').hide();
        });
        $('div.plp-favorites').addClass('enabled'); //enable clicks after favs are set
	 } else {
	     initFavourites();
	 }
}

function removeWishlistJson() {
    var wishListData = localStorage.getItem('wishlistjson') ? JSON.parse(localStorage.getItem('wishlistjson')) : null;
    if (wishListData !== null && wishListData.products && wishListData.products.length > 0) {
        localStorage.removeItem('wishlistjson');
    }
}

function initializeShareWishlistForm() {
	var thisDialog = $('.wishlist-dialog');
    var $form = $('#share-favorites-popup-form:not(.hide)');
    
    $('#itemNameList').selectmenu( {
        classes: {
            "ui-selectmenu-menu": "wl-select-options",
            "ui-selectmenu-button" : "wl-select-field"
        },
        icons: { 
            button: "solid-arrow"
        },
        change: function( event, ui ) {
            if ($(this).val() != '') {
                var selectedList = $(this).val();
                var shareUrl = $('.link-field').val();
                shareUrl = util.removeParamFromURL(shareUrl, 'SelectedList');
                shareUrl = shareUrl.replace('UserWishListID', 'WishListID');
                if (selectedList != 'all') {
                    shareUrl = shareUrl.replace('WishListID', 'UserWishListID');
                    shareUrl = util.appendParamToURL(shareUrl, 'SelectedList', selectedList);
                }
                $('.link-field').val(shareUrl);
                $('.share-error').removeClass('error');
            }
        }
	});
    
    $('.link-field').prop('readonly', true);
    $('.wishlist-share-link').on('click', function() {
        var shareLink = $('.link-field').select();
        $('.wishlist-share-link span').toggle();
        document.execCommand('copy');
        setTimeout(function(){ 
            $('.wishlist-share-link span').toggle();
        }, 5000);
        aep.coreCall('shareList', $form.find('#itemNameList-button').text().trim(), 'link');
    })

    $(document).on('click', '.share-by-email', function (e) { 
        e.preventDefault();
        progress.show(thisDialog);
        var shareUrl = $('.link-field').val();
        ajax.load({
            url: shareUrl,
            type: 'GET',
            async: false,
            callback: function (data) {
                var shareBtn = $('.share-by-email');
                var lineBreak = '%0A';
                if ($(data).find('.product-tile').length > 0) {
                    var emailSubject = shareBtn.data('subject');
                    var emailBody = shareBtn.data('msg') + ' ' + encodeURIComponent(shareUrl) + lineBreak + lineBreak;

                    $(data).find('.product-tile').each(function() {
                        var itemName = $(this).find('.name-link').text().trim();
                        emailBody += Resources.SHARE_PRODUCT_NAME + ' ' + encodeURIComponent(itemName) + lineBreak;

                        var itemColor;
                        var qs = util.getQueryStringParams($(this).find('.thumb-link').attr('href'));
                        $.each( qs, function( key, value ) {
                            if (key.indexOf('colorname') > -1) {
                                itemColor = value;
                            }
                        });
                        emailBody += Resources.SHARE_COLOUR + ' ' + encodeURIComponent(itemColor) + lineBreak;

                        var itemID = $(this).data('itemid');
                        emailBody += Resources.SHARE_STYLE_NUM + ' ' + itemID + lineBreak;

                        emailBody += lineBreak;
                    });
                    document.location = 'mailto:?subject=' + emailSubject + '&body=' + emailBody;

                    aep.coreCall('shareList', $('#share-favorites-popup-form:not(.hide)').find('#itemNameList-button').text().trim(), 'email');

                } else {
                    $('.share-error').addClass('error');
                }
                progress.hide();
            }
        });
    });
        

    $form.on('click', '.wishlist-send-email', function (e) {
        e.preventDefault();
        if (!$form.valid()) {
            return false;
        }

        var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
        var options = {
            url: url,
            data: $form.serialize(),
            type: 'POST'
        };
        progress.show(thisDialog);
        $.ajax(options).done(function (data) {
        	progress.hide();
            if (typeof (data) !== 'string') {
            	thisDialog.addClass('share-done').find('.share-wl-title').text('');
                $form.addClass('hide');
                $('.share-favorites-popup').find('.success-block').removeClass('hide').css('display', 'flex');
                setTimeout(function(){ 
                	thisDialog.fadeOut('slow', function(){
                		$('.dialog-content', thisDialog ).dialog('close');
	                });
                }, 3000);
            }
        });
    });
    validator.init();
}

function toogleView() {
    $('.page-display-toggle input[type="radio"]').on('click',function(){
    	var tilesContainer = $('.tiles-container');
        if($(window).width() > 959) {
        	this.id =='tile-size-larger' ? tilesContainer.addClass('desk-double') : tilesContainer.removeClass('desk-double mob-double');
        } else {
        	this.id =='tile-size-larger' ? tilesContainer.addClass('mob-double') : tilesContainer.removeClass('desk-double mob-double');
        }
    });
}

function initTileLogin () {
	$('.view-favorites.signIn, a.wl-signin-link').on('click', function (e) {
        e.preventDefault();
        progress.show();
        var ths = $(this);
        var $element = ths.closest('.add-to-favorites'); 
        var $element = ths.hasClass('wl-signin-link') ? '' : ths.closest('.add-to-favorites'); 
        ajax.load({
            url: ths.attr('href'),
            type: 'GET',
            async: false,
            callback: function (data) {

                if (enhancedSignin && data) {
                    var modalClass = 'account-login-register-modal';
                    var getSignInData = enhancedSignin.modal.isValid(data, modalClass);
                    if (getSignInData) {
                        return;
                    }
                }

                if ($(data).find('.login-box-content').length > 0) {
                    openLoginModal(data, $element);
                    if(ths.hasClass('create-account-link')) {
                    	$('#createlogin-tab').trigger('click');
                    }
                } else {
                    page.redirect(Urls.wishlistShow);
                }
                progress.hide();
                aep.coreCall('wishlistSignIn');
                $('.save-favorites-dialog .wishlist-favorites').find('.pw-container input.password').addClass('wlpassword');
            }
        });
    });
}

function initUserLists () {
    $(document).off('click', '.wishlist-create-newlist, .wishlist-edit-list, .wishlist-edit-listItem').on('click', '.wishlist-create-newlist, .wishlist-edit-list, .wishlist-edit-listItem', function (e) {	
        e.preventDefault();
        progress.show();
        dialog.open({
            url: $(this).attr('href'),
            options: {
            	width: 525,
        		draggable: false,
        		dialogClass: 'wishlist-dialog',
                open: function() {
                	if($(window).width() < 960) {
                		$(this).closest('.ui-dialog').addClass('translateYZero');
                	}
                	$('body').css('overflow', 'hidden');
                	$('.wishlist-dialog')
	                    .css('overflow-y', 'auto')
	                    .css('max-height', '100%');
                },
                beforeClose: function () {
                	$('body').css('overflow', 'auto');
                }
            },
            callback: function () {
                progress.hide();
                $('.create-listname-field').on('input', function() {
                    var currentLength = $(this).val().length;
                    if (!validator.profanityCheck($(this).val(), $(this))) {
                    	$(this).closest('form#newlist-popup-form').find('.list-form-error').addClass('error').text(Resources.PROFANITY_INCORRECTCHARACTERSS);
                    	return true;
                    } else if (currentLength >= 20) {
                    	$('.list-form-error').addClass('error').text(Resources.LIST_NAME_LENGTH_ERROR);
                        return false;
                    } else {
                    	$('.list-form-error').removeClass('error').text('');
                    }
                });
                
                wlListActions();
                
                $('.show-wishlist-create-form').on('click', function (e) {
                	$('.create-listname-field').val('');
                	$('#newlist-popup-form, .show-wishlist-create-form').toggleClass('hide');
                });

            }
        });
    });
    
    $('.wishlist-view-all').on('click', function (e) {
    	e.preventDefault();
        progress.show($(this));
    	var $targetDiv = $(this).parents(".wl-list-container");
        ajax.load({
            url: $(this).attr('href'),
            type: 'GET',
            callback: function (response) {
            	progress.hide();
            	if (response) {
                    $targetDiv.find('.search-result-items').append(response);
            		$targetDiv.find('.wishlist-view-all').hide();
            		initFavourites();
                    producttile.init();
            		document.dispatchEvent(new CustomEvent('addQuickShop', { bubbles: true, detail: { checkProducts: false, type: 'link' } }));
                    aep.coreCall('wishlistFullView', $targetDiv.find('.search-result-items .product-tile'));
            	}
            }
        });
    });
    
    $(document).off('click', '.show-create-list').on('click', '.show-create-list', function (e) {	
        e.preventDefault();
        ajax.load({
            url: $(this).attr('href'),
            type: 'GET',
            async: false,
            callback: function (data) {
            	var createListForm = $(data).find('#newlist-popup-form');
                if (createListForm) {
                	$('.wl-confirm-modal .show-create-list').replaceWith(createListForm);
                	$('fieldset').addClass('inline-btn');

                    $('.create-listname-field').on('input', function() {
                        var currentLength = $(this).val().length;
                        if (currentLength >= 20) {
                            $('.list-form-error').addClass('error').text(Resources.LIST_NAME_LENGTH_ERROR);
                            return false;
                        } else {
                            $('.list-form-error').removeClass('error').text('');
                        }
                    });
                }
            }
        });
    });
}

function initAddToCart () {
    $('.wl-add-to-cart').on('click', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        progress.show($('#container-wrap'));
        var $tileContainer = $(this).closest('.grid-tile');
        var $form = $tileContainer.find('.js-add-to-cart-form');
        var $button = $(this);
        var masterProdId = $form.find('input[name="masterProdId"]').val();
        var productSku = $form.find('input[name="productSKU"]').val();
        var url = util.appendParamToURL(Urls.checkQtyLimitExceeded, 'productid', masterProdId);
        url = util.appendParamToURL(url, 'qty', 1);
        url = util.appendParamToURL(url, 'productSKU', productSku);
        $.ajax({
            dataType: 'json',
            contentType: 'application/json',
            url: url,
            type: 'GET'

        }).done(function(response) {
            if (response.lmtExd) {
                progress.hide();
                $form.find('.limit-exceeded').text('Maximum Quantity Reached');
                return false;
            } else {
                addWishListItemToCart($form).then(function(response) {
                    progress.hide();
                    minicart.show(response, $form, $button);
                }.bind(this));
            }
        });
    });
}

function addWishListItemToCart(form) {
	var $form = $(form);
	var url = util.ajaxUrl(Urls.addProduct)
	return Promise.resolve($.ajax({
        type: 'POST',
        async: false, //JIRA PREV-305:SG issue: No of items in cart value not displayed as expected.Added async:false.
        url: url,
        data: $form.serialize()
    })).then(function(response) {
        // handle error in the response
        if (response.error) {
            throw new Error(response.error);
        }
        return response;
    });
}

function wlListActions() {
    $(document).off('click', '.wishlist-createlist-btn, .list-delete-btn, .wishlist-rename-list').on('click', '.wishlist-createlist-btn, .list-delete-btn, .wishlist-rename-list', function (e) {	
		e.preventDefault();
		var nameRegexp =  /^[A-Za-z0-9 ]+$/;
        var country = window.Resources.COUNTRY_CODE;
        if (country && country == 'KR') {
            nameRegexp =  /^[A-Za-z0-9\u3131-\uD79D ]+$/;
        } else if (country && country == 'TW') {
            nameRegexp =  /^[A-Za-z0-9\u4e00-\u9eff ]+$/;
        }
		var listName = $('.create-listname-field').val();
		$('.list-form-error').removeClass('error').text('');
		if (!validator.profanityCheck(listName, $(this))) {
			$(this).closest('form#newlist-popup-form').find('.list-form-error').addClass('error').text(Resources.PROFANITY_INCORRECTCHARACTERSS);
			return true;
        } else if (listName == '' || listName == null) {
			$('.list-form-error').addClass('error').text(Resources.LIST_NAME_ERROR);
			return;
		} else if (!nameRegexp.test(listName)) {
			$('.list-form-error').addClass('error').text(Resources.LIST_NAME_REGEX_ERROR);
			return;
		}
		var $createListForm = $('#newlist-popup-form');
		var url = util.appendParamToURL($createListForm.attr('action'), $(this).attr('name'), true);
	    if ($(this).hasClass('wishlist-rename-list')) {
	        var selectedListName = $('.selectedListName').val();
	        url += '&selectedListName='+selectedListName;
	    }
	    $('.create-listname-field').val(listName.trim());
		ajax.load({
	        url: url,
	        type: 'POST',
	        data: $createListForm.serialize(),
	        callback: function (data) {
	        	var response = data != null ? JSON.parse(data) : null;
	        	if (response.success) {
	        		if ($('.pt_product-search-result, .pt_product-details, .pt_guidedshopping').length > 0) {
	        			setTimeout(function() {
            				closeModal();
            			}, 300);
	        		} else {
	        			dialog.close();
		        		page.redirect(Urls.wishlistShow);
	        		}
                    aep.coreCall('createList', listName, $(this).attr('url'));
	        	} else if (!response.success && response.errorMessage) {
	        		$('.maxlist-error').addClass('error').text(response.errorMessage);
	        	}	        	
	        }
	    });
	});
    
    $('.delete-list, .cancel-delete').on('click', function (e) {
    	var modalHeight = $('.wishlist-dialog').height();
    	$('.newlist-popup').css('display', 'none');
    	$('#dialog-container').css('min-height', modalHeight);
    	$('.remove-list-confirmation, .delete-list, .wl-title, .form-row-input, .wishlist-rename-list').toggle('hide');
    	$('.list-delete-btn').toggleClass('secondary');
    	setTimeout(function() {
    		$('.newlist-popup').css('display', 'block');
    		$('#dialog-container').css('min-height', 'auto');
		}, 500);
    });

    $('#itemNameList').selectmenu( {
    	classes: {
    	    "ui-selectmenu-menu": "wl-select-options",
    	    "ui-selectmenu-button" : "wl-select-field"
    	},
    	icons: { 
    		button: "solid-arrow"
    	},
    	open: function( event, ui ) {
    		$('#itemNameList-menu .ui-menu-item:first-child').addClass('hide');
    	},
    	change: function( event, ui ) {
    		if ($(this).val() != '') {
    			var $moveToListForm = $('#wishlist-movelist-form');
    			
    			var url = util.appendParamToURL($moveToListForm.attr('action'), 'listName', $(this).val());
    			ajax.load({
                    url: url,
                    type: 'GET',
                    data: $moveToListForm.serialize(),
                    callback: function (data) {
                    	var response = data != null ? JSON.parse(data) : null;
                    	if (response.success) {
                    		if ($('.pt_product-search-result, .pt_product-details, .pt_guidedshopping').length > 0) {
                    			setTimeout(function(){
                    				closeModal();
                    			}, 300);
        	        		} else {
        	        			dialog.close();
                        		page.redirect(Urls.wishlistShow);
        	        		}
                            aep.coreCall('moveToList', $(this).attr('url'));
                    	} else if (!response.success && response.errorMessage) {
                    		$('.maxlist-error').addClass('error').text(response.errorMessage);
                    	}
                    }
                });
    		}
        	
    	}
	});
}

function closeModal() {
	var thisModal = $('.wishlist-dialog');
	if ((thisModal.hasClass('translateYZero')) && $(window).width() < 960) {
		// Slide Down
		thisModal.removeClass('translateYZero');
		$('.ui-widget-overlay').fadeOut('slow', function() {
			$(this).remove();
		});
		setTimeout(function() { 
			$('body').css('overflow', 'auto');
			thisModal.find('.ui-dialog-content').dialog('close').remove();
			thisModal.remove();
    	},750 );
	} else {
		// Fade Out
		thisModal.fadeOut(300);
		$('.ui-widget-overlay').remove();
		setTimeout(function() { 
			thisModal.find('.ui-dialog-content').dialog('close').remove();
			thisModal.remove();
		}, 500);
	}
}

var initCategorySwiper = function() {
	var categorySwiper = new Swiper('.wl-category-slider', {
	    allowTouchMove: true,
	    spaceBetween: 16,
	    slidesPerView: 4,
	    virtualTranslate: true,
        breakpoints: {
        	600: {
				slidesPerView: 1.45,
                virtualTranslate: false
		    },
		    768: {
				slidesPerView: 3,
                virtualTranslate: false
		    },
		    1024: {
		        slidesPerView: 3,
		        virtualTranslate: false
		    },
		    1200: {
		        slidesPerView: 4,
		    }
        }
	});
};

exports.init = function() {
    var favLinkdesktop = $(".product-set .product-col-1").find(".product-primary-image.desktop-only");
    favLinkdesktop.find("div.pdp-favorites").insertAfter(favLinkdesktop.find("a.product-image img.primary-image"));

    $('#editAddress').on('change', function() {
        page.redirect(util.appendParamToURL(Urls.wishlistAddress, 'AddressID', $(this).val()));
    });

    //add js logic to remove the , from the qty feild to pass regex expression on client side
    $('.option-quantity-desired input').on('focusout', function() {
        $(this).val($(this).val().replace(',', ''));
    });
    removeFavorites();
    addFavorites();
    toogleView();

    producttile.init();
    pagination.init();
    initTileLogin();
    initUserLists();
    initBannerScroll.init();
    removeWishlistJson(); // On pageload Remove wishlistjson localeStorage
    setFavourites(); // initFavourites is called internally in this function if localeStorage does not have wishlistjson

    if ($('.wl-list-container .wl-add-to-cart').length > 0) {
        initAddToCart();
    }
    
    var isUserLoggedin = $('.pt_account.authenticated-user');
    if(isUserLoggedin && isUserLoggedin.length > 0) {
    	setTimeout(function (){
        	//waiting to load content from asset
        	initCategorySwiper();
        }, 2000);

        accountMenu.accountMenuToggle();
        myrlQuickFilterDropDown.init();
    }

    $('#cart-table a.move-to-wishlist-from-cart').on('click',function(){
        cartAddFavorites();
    });
    
    $(".share-favorites").on("click",function(e){
        e.preventDefault();
        var countryLocale = "en_GB";  
        var url = util.appendParamToURL($(this).attr('href'), 'locale',countryLocale);
        progress.show();
        dialog.open({
            url: url,
            options: {
                width: 533,
                draggable: false,
                dialogClass: 'wishlist-dialog',
                open: function() {
                    if($(window).width() < 960) {
                        $(this).closest('.ui-dialog').addClass('translateYZero');
                    }
                    initializeShareWishlistForm();
                    $('body').css('overflow', 'hidden');
                    $('.wishlist-dialog')
                        .css('overflow-y', 'auto')
                        .css('max-height', '100%');
                },
                beforeClose: function () {
                    $('body').css('overflow', 'auto');
                }
            },
            callback: function () {
                progress.hide();
            }
        });
    });
};
exports.setFavourites = setFavourites;
exports.resetPassword = resetPassword;
exports.siginOverLay = siginOverLay;
exports.showConfirmationMsg = showConfirmationMsg; 